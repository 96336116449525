import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { IconBell } from '@tabler/icons-react';
import { useNotice } from 'contexts/NoticeContext';

export default function NoticeButton() {
  const { openNotice } = useNotice();

  const theme = useTheme();

  return (
    <Box
      sx={{
        ml: 2,
        mr: 1
      }}
    >
      <ButtonBase sx={{ borderRadius: '12px' }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            ...theme.typography.menuButton,
            transition: 'all .2s ease-in-out',
            borderColor: theme.typography.menuChip.background,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light
            }
          }}
          onClick={openNotice}
          color="inherit"
        >
          <IconBell stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    </Box>
  );
}
