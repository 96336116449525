import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { API } from 'utils/api';
import { marked } from 'marked';

export const NoticeContext = createContext();

export function useNotice() {
  return useContext(NoticeContext);
}

// eslint-disable-next-line
export const NoticeProvider = ({ children }) => {
  const [notice, setNotice] = useState(localStorage.getItem('notice'));
  const [isOpen, setOpen] = useState(false);

  const openNotice = () => {
    setOpen(true);
  };

  const closeNotice = () => {
    setOpen(false);
  };

  const checkNotice = useCallback(async () => {
    try {
      const res = await API.get('/api/notice');
      const { success, data } = res.data;
      if (success) {
        let oldNotice = localStorage.getItem('notice');
        if (data !== oldNotice && data !== '') {
          const htmlNotice = marked(data);
          setNotice(htmlNotice);
          setOpen(true);
          localStorage.setItem('notice', data);
        }
      }
    } catch (error) {
      console.error('Failed to fetch notice:', error);
    }
  }, []);

  useEffect(() => {
    checkNotice().then();
  }, [checkNotice]);

  return <NoticeContext.Provider value={{ notice, isOpen, openNotice, closeNotice }}>{children}</NoticeContext.Provider>;
};

export default NoticeProvider;
