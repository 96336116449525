import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function CustomizedDialogs({ title, content, open, handleClose }) {
  return (
    <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth={'sm'}>
      <DialogTitle
        sx={{ margin: '0px', fontWeight: 700, lineHeight: '1.55556', padding: '24px', fontSize: '1.125rem' }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
    </BootstrapDialog>
  );
}

CustomizedDialogs.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
