// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { isAdmin } from 'utils/common';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuList = () => {
  const userIsAdmin = isAdmin();
  const siteInfo = useSelector((state) => state.siteInfo);

  return (
    <>
      {menuItem.items.map((item) => {
        if (item.type !== 'group') {
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
        }

        const filteredChildren = item.children.filter((child) => {
          if (child.id === 'midjourney' && !siteInfo.MjEnabled) {
            return false;
          }

          return !child.isAdmin || userIsAdmin;
        });

        if (filteredChildren.length === 0) {
          return null;
        }

        return <NavGroup key={item.id} item={{ ...item, children: filteredChildren }} />;
      })}
    </>
  );
};

export default MenuList;
