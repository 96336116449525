import { useNotice } from 'contexts/NoticeContext';
import CustomizedDialogs from 'ui-component/Dialog';

const NoticeDialogs = () => {
  const { isOpen, closeNotice, notice } = useNotice();

  return <CustomizedDialogs title="公告" content={notice} open={isOpen} handleClose={closeNotice} />;
};

export default NoticeDialogs;
